import React from 'react'
import PropTypes from 'prop-types'

const GalleryItem = ({ id, href, image, title, lang }) => {
    return (
        <article key={id} className="6u 12u$(xsmall) work-item">
            <a
                href={(lang !== "default" && lang !== "ca") ? `/${lang}${href}` : href}
                className="image fit thumb"
                open-button-name={(lang !== "default" && lang !== "ca") ? 'Open' : 'Obre'}
                style={{ position: 'relative', height: '170px' }}
            >
                <img
                    src={image}
                    alt={title.default}
                    style={{ height: '100%', objectFit: 'cover' }}
                />
                <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(107, 106, 106, 0.5)' }} />
                <h3 style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                    fontSize: '1.2em',
                    color: '#fff'
                }}>{title[lang]}</h3>
            </a>
        </article >
    )
};


GalleryItem.displayName = 'GalleryItem'
GalleryItem.propTypes = {
    id: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    caption: PropTypes.string,
}

export default GalleryItem