import it1 from '../../assets/images/it1.jpg'
import it2 from '../../assets/images/it2.jpg'
import it3 from '../../assets/images/it3.jpg'

export const TOPICS = [
  {
    id: '1',
    image: it3,
    title: {
      default: 'Sessions individuals de musicoteràpia infantil',
      ca: 'Sessions individuals de musicoteràpia infantil',
      en: 'Individual music therapy sessions'
    },
    href: '/individual-sessions',
  },
  {
    id: '2',
    image: it2,
    title: {
      default: 'Sessions i tallers d’estimulació musical en família ',
      ca: 'Sessions i tallers d’estimulació musical en família ',
      en: 'Musical stimulation family workshops and sessions'
    },
    href: '/music-stimulation',
  },
  // {
  //   id: '3',
  //   image: it1,
  //   title: {
  //     default: 'Tallers de musicoteràpia inclusiva',
  //     ca: 'Tallers de musicoteràpia inclusiva',
  //     en: 'Sessions structure'
  //   },
  //   href: '/sessions',
  // },
]