import image from '../../assets/images/item.jpg'

export const TOPICS = [
  {
    id: '1',
    image,
    title: {
      default: 'Què és?',
      ca: 'Què és?',
      en: 'What is it?'
    },
    href: '/definition',
  },
  {
    id: '2',
    image,
    title: {
      default: 'Objectius',
      ca: 'Objectius',
      en: 'Goals'
    },
    href: '/goals',
  },
  {
    id: '3',
    image,
    title: {
      default: 'Estructura de les sessions',
      ca: 'Estructura de les sessions',
      en: 'Sessions structure'
    },
    href: '/sessions',
  },
  {
    id: '4',
    image,
    title: {
      default: 'Seguiment',
      ca: 'Seguiment',
      en: 'Follow-up'
    },
    href: '/followup',
  }
]