import React from 'react'
import Gallery from '../components/TopicGallery/Gallery'
import Layout from '../components/Layout'
import { TOPICS } from '../components/TopicGallery/queFaigList'

const HomeIndex = ({ location }) => {


  return (
    <Layout isMain={true} location={location} lang={'ca'}>
      <section id="one">
        <h2>
          Qui sóc ?
        </h2>

        <p>
          Em dic Sara Cillan Grau i he realitzat el <strong>Grau en Educació Infantil</strong> amb menció de música a la UAB, Barcelona.
        </p>
        <p>
          Després de dur a terme les pràctiques i treballar un temps a Alemanya, vaig adonar-me de la repercussió que tenia la música en infants amb alguna necessitat específica. És per això que vaig decidir fer el Màster de Musicoteràpia a l'ESMUC, Barcelona.
        </p>
        <p>
          En l’àmbit musicoterapeutic, una vegada finalitzat el màster he assistit a diferents cursos de Musicoteràpia relacionats amb l’Autisme, la diversitat funcional i la musicoteràpia infantil.
        </p>
        <p>
          He treballat a la Fundació Cromosuma, a Barcelona, amb infants amb Síndrome de Down durant uns anys; una vegada finalitzada aquesta feina, vaig decidir fer sessions individuals a domicili amb infants amb necessitats diverses; i, finalment, he habilitat una sala al meu propi domicili, a Granollers, on actualment realitzo les sessions.
        </p>
        <p>
          A més, fa anys que duc a terme sessions <strong>d’estimulació musical amb infants de 0-3</strong> a Granollers i en alguna ocasió he realitzat <strong>tallers inclusius de música en família</strong> a diferents poblacions.
        </p>
      </section>

      <section id="two">
        <h2>La Musicoteràpia</h2>
        <p style={{ fontStyle: 'italic' }}>
          “La musicoteràpia és l’<strong>ús professional de la música</strong> i dels seus elements (so, ritme, melodia, harmonia) com a intervenció en entorns mèdics, educatius i quotidians amb individus, grups, famílies o comunitats amb una <strong>finalitat terapèutica i preventiva</strong>, per tal d’ajudar a millorar la seva qualitat de vida així com el <strong>benestar físic, social, comunicatiu, emocional, intel·lectual i el benestar</strong>.
          <br />
          És una professió de l’àmbit sanitari de caràcter humanista i creatiu, ja que actua sobre les emocions, la creativitat, els valors humans, etc.”
          <span style={{ display: 'block', textAlign: 'right' }}>WFMT, 2011</span>
        </p>
        <p>
          A les sessions de musicoteràpia s’acompanya a l’infant en el seu desenvolupament integral i es treballen aquells aspectes que siguin necessaris (memòria, atenció, espera, emocions, comunicació...) alhora que gaudeix fent música.
          <br />
          Dins de les sessions hi ha un ambient de no judici, respecte i acompanyament. A més, es fomenta un espai de lliure expressió, on els infants poden expressar-se emocionalment de la forma que els és més còmode (instrumentalment, corporalment, a partir de la veu...).
        </p>
      </section>
      <section id="three">
        <h2>Què faig?</h2>
        <Gallery lang={'ca'} topics={TOPICS} />
        <span>També es fan tallers personalitzats de musicoteràpia inclusiva sota demanada.
          <br />Per a més informació  <a href="mailto:musicoterapia@saracillan.com">contacta'm</a>.
        </span>
      </section>
    </Layout>
  )
}

export default HomeIndex
